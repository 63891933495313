<template>
  <b-card>
    <b-tabs>
      <b-tab id="application" title="Application">
        <student-application-documents  />
      </b-tab>
      <b-tab id="visa" title="Visa">
        <student-visa-documents />
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import { BCard, BTab, BTabs } from "bootstrap-vue";
import StudentApplicationDocuments from "./Components/StudentApplicationDocuments.vue";
import StudentVisaDocuments from "./Components/StudentVisaDocuments.vue";

export default {
  components: {
    BCard,
    StudentApplicationDocuments,
    StudentVisaDocuments,
    BTab,
    BTabs,
  },
  data() {
    return {};
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        let type = store.getters["user/getUserDetails"].user_type;
        return type;
      } else {
        return null;
      }
    },
  },
  methods: {
    
      },
};
</script>

<!-- <style scoped>
  .nav-tabs {
    /* background-color: #f0f0f0; */
    padding: 10px;
    border-radius: 5px;
  }

  .nav-item {
    list-style: none;
    display: inline;
    margin-right: 10px; 
  }

  .btn-nav {
    /* background-color: #ffffff;  */
    border:1px solid #5367ff ;
    color: #5367ff; 
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .btn-nav:hover {
    background-color: #8684ff; 
  }
</style> -->
