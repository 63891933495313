<template>
  <b-card>
    <b-table hover :items="services" :fields="fields">
      <template #cell(show)="row">
        <feather-icon
          icon="PlusCircleIcon"
          size="20"
          v-if="
            !row.detailsShowing &&
            row.item.features &&
            row.item.features.length > 1
          "
          @click="row.toggleDetails"
        />
        <feather-icon
          icon="MinusCircleIcon"
          size="20"
          v-if="row.detailsShowing"
          @click="row.toggleDetails"
        />
      </template>
      <template #cell(final_delivery)="row">
        <span v-if="row.item.final_delivery">
          {{ moment(row.item.final_delivery).format("DD MMM, YYYY HH:mm:ss") }}
        </span>
        <flat-pickr
          v-model="row.item.final_delivery"
          v-else-if="['services'].includes(user_type)"
          class="form-control"
          placeholder="Select Date"
          @input="onSearch(row.item.plan_id)"
          :config="{
            dateFormat: 'Y-m-d H:i',
            enableTime: true,
            time_24hr: true,
            altInput: true,
            altFormat: 'd-m-Y H:i',
            allowInput: true,
            noCalendar: false,
          }"
        />
        <span v-else> - </span>
      </template>

      <template #row-details="row">
        <div
          v-if="row.item.plan_id === 6 && row.item.content.length"
          class="pt-3 pb-5"
        >
          <h4 class="my-1 ml-1 p-0" style="min-width: 500px">Content</h4>
          <div v-for="(c, index) in row.item.content" :key="'c_' + index">
            <div style="width: 270px">
              <embed
                :src="generatePdfBlobUrl(c.sat_report_link)"
                width="100%"
                height="200px"
                type="application/pdf"
              />
              <br />
              <div class="d-flex justify-content-between">
                <span>
                  <a
                    target="_blank"
                    :href="generatePdfBlobUrl(c.sat_report_link)"
                    >open</a
                  >
                </span>
                <span>
                  <a
                    download="sat_report"
                    :href="generatePdfBlobUrl(c.sat_report_link)"
                    >download</a
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="shadow-xl rounded-lg border" style="overflow-x: scroll">
          <div class="d-flex border-bottom bg-white">
            <h4 class="my-1 ml-1 p-0" style="min-width: 500px">Features</h4>
            <h4 class="my-1" style="min-width: 200px">Progress</h4>
            <!-- <h4 class="my-1 ml-2" style="min-width: 200px">Team</h4> -->
            <h4 class="mt-1" style="min-width: 200px">Completion Date</h4>
            <h4 class="mt-1 ml-5" style="min-width: 200px">Documents</h4>
            <h4 class="mt-1 ml-5" style="min-width: 200px">Action</h4>
          </div>
          <div style="background-color: #fafafa">
            <div
              class="d-flex mt-1 border-bottom pb-1 ml-1"
              v-for="(feature, index) in row.item.features"
              :key="'feature_' + index"
            >
              <span class="font-weight-bold" style="min-width: 500px">{{
                feature.feature_name
              }}</span>
              <b-form-checkbox
                :disabled="
                  !feature.user_type_key || feature.user_type_key !== user_type
                "
                :checked="isFeatureCompleted(feature.feature_id)"
                @change="
                  (val) => {
                    if (val) {
                      markFeatureCompleted(
                        row.item.service_id,
                        row.item.plan_id,
                        feature.feature_id
                      );
                    } else {
                      markFeatureCompleted(
                        row.item.service_id,
                        row.item.plan_id,
                        feature.feature_id,
                        true
                      );
                    }
                  }
                "
              />
              <div style="min-width: 200px">
                <b-badge
                  class="ml-1 mr-5"
                  :variant="
                    isFeatureCompleted(feature.feature_id)
                      ? 'success'
                      : 'warning'
                  "
                  >{{
                    isFeatureCompleted(feature.feature_id)
                      ? "Complete"
                      : "Pending"
                  }}</b-badge
                >
              </div>
              <div
                v-if="getCompletedFeatureDate(feature.feature_id)"
                class="rounded-lg shadow-sm"
                style="padding: 2px 8px"
              >
                {{
                  moment(getCompletedFeatureDate(feature.feature_id)).format(
                    "DD MMM, YYYY HH:mm:ss"
                  )
                }}
              </div>
              <div
                v-else
                class="rounded-lg shadow-sm"
                style="padding: 2px 8px; min-width: 160px"
              >
                -
              </div>

              <div
                style="min-width: 200px"
                class="d-flex justify-content-center"
              >
                <feather-icon
                  icon="FilePlusIcon"
                  size="20"
                  class="cursor-pointer"
                  stroke="#333"
                  @click="openModal(feature.feature_id, feature.plan_id)"
                  v-if="
                    feature.sfd_file_path === null && feature.feature_id !== 99
                  "
                />

                <div
                  v-else-if="feature.feature_id !== 99 && feature.sfd_file_path"
                >
                  <a :href="FILESURL + feature.sfd_file_path" target="_blank">
                    <feather-icon
                      icon="EyeIcon"
                      size="20"
                      class="cursor-pointer ml-1"
                    />
                  </a>
                </div>

                <div v-if="feature.feature_id === 99">
                  <feather-icon
                    icon="CircleIcon"
                    size="20"
                    class="cursor-pointer ml-1"
                    stroke="#333"
                    fill="#00d09b"
                    @click="getSOPAnswersByStudentId"
                  />
                </div>
              </div>

              <div class="d-flex justify-content-end" style="min-width: 150px">
                <feather-icon
                  icon="EditIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="getFeatureDocById(feature.sfd_id)"
                  v-if="feature.sfd_file_path !== null"
                />
              </div>
            </div>
          </div>
        </div>

        <b-modal
          title="Add Document"
          centered
          hide-footer
          id="add-document-modal"
        >
          <b-form>
            <div>
              <b-form-input
                id="doc-name"
                v-model="upload_doc_data.sfd_name"
                placeholder="Enter document name"
                required
              />
            </div>

            <div class="my-2">
              <b-form-file
                id="doc-file"
                v-model="documentFile"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                required
              />
            </div>

            <div class="d-flex justify-content-end">
              <b-button variant="primary" @click="documentUpload">
                Submit
              </b-button>
            </div>
          </b-form>
        </b-modal>

        <b-modal
          id="modal-questions-view"
          title="SOP Questions"
          size="lg"
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
        >
          <div
            v-for="(item, index) in question_with_answers"
            :key="item.sqa_id"
            class="mb-2"
          >
            <label :for="'question-' + index" style="font-size: 1.2em">{{
              item.sq_question
            }}</label>
            <b-form-textarea
              :id="'question-' + index"
              v-model="item.sqa_answer"
              :rows="2"
            ></b-form-textarea>
          </div>
          <div v-if="question_with_answers.length === 0">
           <h3> SOP form not filled yet</h3>
          </div>
          <div class="d-flex justify-content-end">
            <b-button variant="success" @click="submitAnswers">
              Submit
            </b-button>
          </div>
        </b-modal>
      </template>
      <template #cell(buy_date)="row">
        {{
          moment(row.item.transaction_datetime).format("DD MMM, YYYY - HH:mm")
        }}
      </template>
    </b-table>
  </b-card>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import {
  BCarousel,
  BCarouselSlide,
  BButtonGroup,
  BButton,
  BLink,
  BCardText,
  BTabs,
  BTab,
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BCollapse,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BCol,
  BRow,
  BTable,
  BForm,
  BFormInput,
  BformGroup,
  BFormFile,
  BFormTextarea,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import { BASEURL, FILESURL } from "@/config";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { TokenService } from "@/apiServices/storageService";
import axios from "axios";

export default {
  components: {
    BCardCode,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BCarousel,
    BCarouselSlide,
    BButtonGroup,
    BButton,
    BCardText,
    BTabs,
    BLink,
    BTab,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BRow,
    BCol,
    BTable,
    BBadge,
    flatPickr,
    BForm,
    BFormInput,
    BformGroup,
    BFormFile,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
  },

  data() {
    return {
      services: [],
      student_user_id: null,
      completed_features: [],
      date: "",
      fields: [
        "show",
        "service_name",
        "plan_name",
        "amount",
        "buy_date",
        "final_delivery",
      ],
      debounce: null,
      FILESURL,
      BASEURL,
      documentFile: null,
      documentName: "",
      upload_doc_data: {
        plan_id: null,
        feature_id: null,
        sfd_name: null,
        sfd_file_path: null,
        sfd_id: null,
        student_user_id: null,
      },
      question_with_answers: [],
    };
  },
  methods: {
    async submitAnswers() {
      try {
        const res = await CommonServices.saveSOPAnswers({
          answers: this.question_with_answers,
        });
        if (res.data.status) {
          this.$bvModal.hide("modal-questions-view");
          this.question_with_answers = [];
        }
      } catch (error) {
        console.error("Error in submitting answers", error);
      }
    },
    async getSOPAnswersByStudentId() {
      try {
        const res = await CommonServices.getSOPAnswersByStudentId({
          st_user_id: this.student_user_id,
        });
        if (res.data.status) {
          this.question_with_answers = res.data.data;
          this.$bvModal.show("modal-questions-view");
        }
      } catch (error) {
        console.error("Error in getting SOP answers", error);
      }
    },

    openModal(f_id, p_id) {
      this.upload_doc_data.feature_id = f_id;
      this.upload_doc_data.plan_id = p_id;
      this.upload_doc_data.student_user_id = this.student_user_id;
      this.$bvModal.show("add-document-modal");
    },
    generatePdfBlobUrl(binary) {
      const byteCharacters = atob(binary);
      const byteNumbers = Array.from(byteCharacters, (char) =>
        char.charCodeAt(0)
      );
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      return URL.createObjectURL(blob);
    },
    moment,
    isFeatureCompleted(feature_id) {
      let student_user_id = this.student_user_id;

      const check = this.completed_features.find(
        (feature) =>
          feature.student_user_id == student_user_id &&
          feature.feature_id == feature_id
      );

      if (check) return true;
      return false;
    },

    getCompletedFeatureDate(feature_id) {
      let student_user_id = this.student_user_id;

      const feature = this.completed_features.find(
        (feature) =>
          feature.student_user_id == student_user_id &&
          feature.feature_id == feature_id
      );

      if (feature) return feature.created_at;
      return null;
    },

    async documentUpload() {
      try {
        const AUTH_TOKEN = TokenService.getToken();

        const formData = new FormData();
        formData.append("user_id", this.student_user_id);
        formData.append("sfd_doc", this.documentFile);

        const response = await axios.post(
          `${BASEURL}/common/upload-feature-doc`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              authorization: AUTH_TOKEN,
            },
          }
        );

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Document Uploaded Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.upload_doc_data.sfd_file_path = response.data.data.file_path;
          this.addDoc();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Document Uploaded Failed",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error uploading file ", err);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },
    async addDoc() {
      try {
        const res = await CommonServices.addFeatureDocument(
          this.upload_doc_data
        );
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Document Added",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$bvModal.hide("add-document-modal");
          this.getStudentServicesAvailed(this.student_user_id);
          this.reset_upload_doc_data();
        }
      } catch (error) {
        console.error("Error in adding Document ", error);
      }
    },
    reset_upload_doc_data() {
      this.upload_doc_data = {
        plan_id: null,
        feature_id: null,
        sfd_name: null,
        sfd_file_path: null,
        sfd_id: null,
        student_user_id: null,
      };
    },

    async getFeatureDocById(sfd_id) {
      try {
        const res = await CommonServices.getFeatureDocById(sfd_id);
        if (res.data.status) {
          const doc = res.data.data[0];
          this.upload_doc_data = doc;
          this.upload_doc_data.student_user_id = doc.user_id;
          this.$bvModal.show("add-document-modal");
        }
      } catch (error) {
        console.error("Error in getting feature doc ", error);
      }
    },

    async getStudentServicesAvailed(student_user_id) {
      try {
        const response = await CommonServices.getStudentServicesAvailed({
          student_user_id,
        });
        if (response.data.status) {
          this.services = response.data.data.map((service) => ({
            ...service,
          }));
        }
      } catch (err) {
        console.log("Error in getting syudent availed services ", err);
      }
    },

    onChange() {
      this.$nextTick(() => {
        this.getStudentServicesAvailed();
      });
    },
    onSearch(plan_id) {
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        const row = this.services.find(
          (service) => service.plan_id === plan_id
        );
        if (row) {
          this.saveServiceFinalDelivery(plan_id, row.final_delivery);
        }
      }, 1000);
    },

    async saveServiceFinalDelivery(plan_id, finalDelivery) {
      const payload = {
        student_user_id: this.student_user_id,
        plan_id,
        final_delivery: finalDelivery,
      };

      const res = await CommonServices.saveServiceFinalDelivery(payload);
      if (res.data.status) {
        this.getStudentServicesAvailed(this.student_user_id);
      }
    },

    async markFeatureCompleted(service_id, plan_id, feature_id, unmark) {
      try {
        const response = await CommonServices.markFeatureCompleted({
          student_user_id: this.student_user_id,
          service_id,
          plan_id,
          feature_id,
          unmark,
        });
        if (response.data.status) {
          this.getCompletedFeatures(this.student_user_id);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed to update feature",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        console.log("Error in getting syudent availed services ", err);
      }
    },

    async getCompletedFeatures(student_user_id) {
      try {
        const response = await CommonServices.getCompletedFeatures({
          student_user_id,
        });
        if (response.data.status) {
          this.completed_features = response.data.data;
        }
      } catch (err) {
        console.log("Error in getCompletedFeatures", err);
      }
    },
  },

  beforeMount() {
    const { student_user_id } = this.$route.params;
    if (!isNaN(student_user_id)) {
      this.getStudentServicesAvailed(student_user_id);
      this.getCompletedFeatures(student_user_id);
      this.student_user_id = student_user_id;
    } else {
      console.log("Student User Id is is not a number ", student_user_id);
    }
  },
};
</script>

<style></style>
